import styles from './style.module.scss';
import React from 'react';
import cx from 'classnames';

const TextLink = ({ type, ...props }) => {
    return (
        <a
            className={cx(styles.TextLink, {
                [styles['TextLink-default']]: type === 'default' || !type,
                [styles['TextLink-primary']]: type === 'primary',
                [styles['TextLink-secondary']]: type === 'secondary',
                [styles['TextLink-success']]: type === 'success',
                [styles['TextLink-alert']]: type === 'alert'
            })}
            {...props}
        />
    );
};

export default TextLink;
