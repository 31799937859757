import Container from 'components/container';
import TextLink from 'components/text-link';
import dir from 'utils/dir';
import Link from 'next/link';
import styles from './style.module.scss';

const NotFound = () => (
    <Container>
        <h1 className={styles['NotFound-header']}>404</h1>
        <h1>Page not found.</h1>
        <p>Try:</p>
        <ul>
            <li>
                <Link href={dir.Index.path}>Home</Link>
            </li>
            <li>
                <Link href={dir.About.path}>About</Link>
            </li>
        </ul>
    </Container>
);

export default NotFound;
